import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpRequestService } from 'src/app/services/http-request/http-request.service';
import { apiURL } from 'src/app/models/apis';
import { ToastServiceService } from 'src/app/services/toastService/toast-service.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private _notifications : BehaviorSubject<any> = new BehaviorSubject([]);
  notifications = this._notifications.asObservable(); //SUBSCRIBE THIS VARIABLE

  // private _newNotifications : BehaviorSubject<any> = new BehaviorSubject([]);
  // newNotifications = this._newNotifications.asObservable(); //SUBSCRIBE THIS VARIABLE

  private _count : BehaviorSubject<any> = new BehaviorSubject(0);
  count = this._count.asObservable(); //SUBSCRIBE THIS VARIABLE

  private _showNotifications : BehaviorSubject<boolean> = new BehaviorSubject(false);
  showNotifications = this._showNotifications.asObservable(); //SUBSCRIBE THIS VARIABLE

  public interval: any = null;

  constructor(
    private httpService: HttpRequestService,
    private toastService: ToastServiceService
  ){}

  getNotifications(): void{
    this.httpService.request(apiURL.notifications.getAll, {}).subscribe((response : any) => {
      if (response['status'] === 'success') {
        this._showNotifications.next(false);
        response['data'].map((record: any)=>{
          record.alert_time = new Date(record.alert_time);
        })
        this.countUnread(response['data'], 'I');
        this._notifications.next(response['data']);
        this._showNotifications.next(true);
      }
    })
  };

  getNewNotifications(): void{
    this.httpService.request(apiURL.notifications.getNew, {}).subscribe((response : any) => {
      if (response['status'] === 'success') {
        this._showNotifications.next(false);
        response['data'].map((record: any)=>{
          record.alert_time = new Date(record.alert_time);
        })
        this.countUnread(response['data'],'N');
        var notifications = this._notifications.value;
        notifications = notifications.concat(response.data);
      
        this._notifications.next(notifications);
        if(response['data'].length)
          this.toastService.show('Notifications',response['data'].length + ' new notification','bg-info' ,{})
        
         this._showNotifications.next(true);
      }
    })
  };

  countUnread(data: any, type:any): void{
    var count: any;
    if(type === 'I'){
      count = 0;
    }else{
      count = this._count.value;
    }

    data.map((record: any) => {
      if(record.read_flag == 'N')
        count++;
    });

    this._count.next(count);
  }
  setRead(id: any): void{
    this.httpService.request(apiURL.notifications.setRead, {id:id}).subscribe((response : any) => {
      if (response['status'] === 'success') {
        this.getNotifications();
      }
    })
  };

  delete(id: any): void{
    this.httpService.request(apiURL.notifications.delete, {id:id}).subscribe((response : any) => {
      if (response['status'] === 'success') {
        this.getNotifications();
      }
    })
  };

  setReadAll(): void{
    this.httpService.request(apiURL.notifications.setReadAll, {}).subscribe((response : any) => {
      if (response['status'] === 'success') {
        this.getNotifications();
      }
    })
  };

  deleteAll(): void{
    this.httpService.request(apiURL.notifications.deleteAll, {}).subscribe((response : any) => {
      if (response['status'] === 'success') {
        this.getNotifications();
      }
    })
  };
}
