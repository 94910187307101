<div class="header-container">
    <div class="logo-container" *ngIf="screenOrientation != 'PM'">
        <!-- <img src="assets/images/one-logo.png"> -->
         <h2 class="text-center">oneTrack </h2>
        <!-- <span>{{subscribe.portal_type}}</span> -->
    </div>
    <button type="button" class="btn btn-default sidebar-collapse" (click)="sidepanel.toggleSidemenu()">
        <fa-icon icon="bars" class="menu-collapse"></fa-icon>
    </button>
    <div routerLink="/dashboard" class="d-flex w-100 justify-content-center align-items-center" *ngIf="screenOrientation == 'PM'">
        <img class="login-logo-dash" src="https://instaswapcdn.s3.ap-south-1.amazonaws.com/logo-color-instaswap.png" />
    </div>
    
    <app-header-filters class="ml-auto"></app-header-filters>
    <div class="buttons">
        <div class="search-container" *ngIf="screenOrientation != 'PM'">
            <fa-icon icon="search" class="search-icon"></fa-icon>
            <input type="text" class="form-control search-devices" placeholder="Search devices (by name)" [(ngModel)]="deviceSearch" (ngModelChange)="searchDevice()">
            <div class="list-container" *ngIf="subscribe.showList">
                <div class="list-content" *ngIf="device_list.length > 0">
                    <div class="pointer" *ngFor="let device of device_list" (click)="openDetails(device.code)">{{device.name}}</div>
                </div>
                <div class="no-data" *ngIf="device_list.length == 0">
                    <h6>No Devices Found</h6>
                </div>
            </div>
        </div>  
        <button class="btn btn-default notifications round-buttons" (click)="sidepanel.toggleSidePanel('NOTIFICATION')">
            <fa-icon icon="bell"></fa-icon>
            <span class="badge rounded-pill bg-danger notifications-number">
                {{notification_count}} 
            </span>
        </button>
        <div ngbDropdown class="menu round-buttons mr-2" placement="bottom-right">
            <button class="btn btn-default menu-button" ngbDropdownToggle>{{short_name}}</button>
            <div ngbDropdownMenu class="dropdown-menu">
                <button ngbDropdownItem class="dropdown-list name">{{full_name}}</button>
                <button ngbDropdownItem class="dropdown-list" routerLink="profile">My Profile</button>
                <button ngbDropdownItem class="dropdown-list" routerLink="change-password">Change Password</button>
                <button ngbDropdownItem class="dropdown-list" (click)="sharedService.sessionExpired('logout')">Log Out</button>

            </div>
        </div>
    </div>
</div>

