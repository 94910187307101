import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-alerts-notifications',
  templateUrl: './alerts-notifications.component.html',
  styleUrls: ['./alerts-notifications.component.scss']
})
export class AlertsNotificationsComponent implements OnInit {

  data: any = [];
  constructor(
    public notificationService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.notificationService.notifications.subscribe((data) => {
      this.data = data;
    });
  }

}
