<div class="app-container">
    <div id="header-container" *ngIf="loginService.loginStatus" (click)="hideSearchList()">
        <app-header></app-header>
    </div>
    <div *ngIf="loginService.loginStatus" class="sidebar" [ngClass]="{'sidemenu-container' : !menuCollapsed , 'sidemenu-container-collapsed':menuCollapsed}" (click)="hideSearchList()">
        
        <app-sidemenu></app-sidemenu>
    </div>
    <div [ngClass]="{'right-container' : loginService.loginStatus && !menuCollapsed , 'right-container-expanded': loginService.loginStatus && menuCollapsed , 'login-screen': !loginService.loginStatus}">
        
        <div [ngClass]="{'screen-content': loginService.loginStatus, 'login-screen':!loginService.loginStatus}" (click)="hideSearchList()">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="mobile-overlay pointer" *ngIf="showOverlay && menuCollapsed == false" (click)="sidepanel.toggleSidemenu()"></div>
    <!-- <div [ngClass]="{'panel-container': !panelCollapsed, 'panel-container-collapsed': panelCollapsed}">
        <app-side-panel></app-side-panel>
    </div> -->
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>