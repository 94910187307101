import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BasicTableModule } from '../basic-table/basic-table.module';
import { TableModalComponent } from './table-modal.component';

@NgModule({
  declarations: [
    TableModalComponent
  ],
  imports: [
    SharedModule,
    BasicTableModule
  ],
  exports:[
    TableModalComponent
  ]
})
export class TableModalModule { }
