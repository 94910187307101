import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons';
import {faOutdent, fas} from '@fortawesome/free-solid-svg-icons';
import { NgPipesModule } from 'ngx-pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderModule } from 'src/app/components/loader/loader.module';
import { NaFilterPipe } from '../pipes/naFilter/na-filter.pipe';
import { CellDisplayModule } from '../pipes/cell-display/cell-display.module';

@NgModule({
  declarations: [
    NaFilterPipe
  ],
  imports: [
    CommonModule,
  ],
  providers: [],
  exports: [
    CommonModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    NgPipesModule,
    NgSelectModule,
    LoaderModule,
    NaFilterPipe,
    CellDisplayModule
  ]
})
export class SharedModule { 
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fab, fas);
    library.addIcons(
      faOutdent
    )
  }
}
