import { SubscriptionService } from './services/subscription/subscription.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { LoginStatusService } from './services/login-status/login-status.service';
import { SidepanelsService } from './services/sidepanels/sidepanels.service';
import { config } from 'src/environments/config';
import { NotificationsService } from './services/notifications/notifications.service';
import { HttpRequestService } from './services/http-request/http-request.service';
import { ToastServiceService } from './services/toastService/toast-service.service';
import { apiURL } from './models/apis';
import { Title } from '@angular/platform-browser';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'oneTrack | Instaswap';
  showMenu: boolean = false;

  menuCollapsed: any;
  panelCollapsed: any;
  loginStatus!: number;

  showOverlay: boolean = false;

  interval: any;

  // favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    public loginService: LoginStatusService,
    private router: Router,
    public sidepanel: SidepanelsService,
    private subscribe: SubscriptionService,
    public notificationService: NotificationsService,
    public httpService:HttpRequestService,
    public toastService: ToastServiceService,
    private titleService: Title,
    private locationStrategy: LocationStrategy,
  ){
  }

  ngOnInit(): void{
    this.getConfig();
    var stored_data = localStorage.getItem(config.license_code);
    if(stored_data !== null){
      var license_code = JSON.parse(stored_data);
      this.loginStatus = parseInt(license_code.login_status);
      
      if (this.loginStatus) {
        this.subscribe.portal_type = license_code.user_details.portal_type

        
        // var page = (license_code.user_details.default_page) ? license_code.user_details.default_page : '/dashboard'
        // this.router.navigate([page]);
          
        this.loginService.loginStatus = 1;
        this.notificationService.getNotifications();

        // this.notificationService.interval = setInterval(()=> {
        //   this.notificationService.getNewNotifications();
        // },1*10*1000);

      } 
      else {
        this.router.navigate(['/login'])
      }
    }
    else{
      this.router.navigate(['/login'])
    }

    this.sidepanel.collapsed.subscribe((value)=>{
      this.menuCollapsed = value;
    });

    this.subscribe.setDisplayMode();

    if(this.subscribe.display_mode == 'PM')
      this.showOverlay = true;
    else
      this.showOverlay = false;
    if(this.subscribe.display_mode == 'PM' || this.subscribe.display_mode == 'LM'){
      this.sidepanel.toggleSidemenu()
    }

    this.locationStrategy.onPopState(() => {
      if(location.pathname == '/login' || this.router.url == '/login'){
        window.history.forward();
      }
    })
  };

  hideSearchList(): void{
    this.subscribe.showList = false;
  };

  getConfig(): void{
    var payload = {
      hostname: window.location.hostname
    };

    this.httpService.request(apiURL['config'] , payload).subscribe((response : any )=> {
      if(response['status'] === 'success'){
        if(response.data)
          this.subscribe.url_config = JSON.parse(response.data.wa_config);
        else
          this.subscribe.url_config = this.subscribe.default_config;
        
        // this.titleService.setTitle(config.site_title + ' | ' + this.subscribe.url_config.info.siteTitle);
        // this.favIcon.href = this.subscribe.url_config.img.favicon;
      }
    }
  )
  }

  ngOnDestroy(): void{
    //clearInterval(this.notificationService.interval);
  };
}