import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss']
})
export class BasicTableComponent implements OnInit {
  @Input() table_data: any; 

  constructor() { }

  ngOnInit(): void {
    console.log(this.table_data);
    
  }

}
