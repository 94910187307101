export const sidemenuItems: any = {
    poweredBy: 'https://res.cloudinary.com/emurontech/image/upload/v1609401829/logos/logo_emuron.png',
    menus: [
        {
            "items" : [],
            "mmenu_code" : "SM01",
            "mmenu_color": "text-success",
            "mmenu_icon" : "home",
            "mmenu_link" : 'dashboard',
            "mmenu_name" : "Dashboard"
        },
        {
            "items" : [
                {
                    "module_code" : "SS0003",
                    "module_link" : "list-view",
                    "module_name" : "List View",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0004",
                    "module_link" : "map-view",
                    "module_name" : "Map View",
                    "module_icon" : "car-battery"
                },
            ],
            "mmenu_code" : "SM02",
            "mmenu_color": "text-warning",
            "mmenu_icon" : "compass",
            "mmenu_link" : null,
            "mmenu_name" : "Tracking"
        },
        {
            "items" : [
                {
                    "module_code" : "SS0005",
                    "module_link" : "geofencing-alerts",
                    "module_name" : "Geofencing Alerts",
                    "module_icon" : "search-location"
                },
                {
                    "module_code" : "SS0006",
                    "module_link" : "bms-alerts",
                    "module_name" : "BMS Alerts",
                    "module_icon" : "exclamation-triangle"
                },
                {
                    "module_code" : "SS0007",
                    "module_link" : "other-alerts",
                    "module_name" : "Other Alerts",
                    "module_icon" : "exclamation-circle"
                }
            ],
            "mmenu_code" : "SM03",
            "mmenu_color": "text-danger",
            "mmenu_icon" : "exclamation-triangle",
            "mmenu_link" : null,
            "mmenu_name" : "Alerts"
        },
        {
            "items" : [],
            "mmenu_code" : "SM04",
            "mmenu_color": "text-primary",
            "mmenu_icon" : "chart-pie",
            "mmenu_link" : "reports",
            "mmenu_name" : "Reports"
        },
        {
            "items" : [
                {
                    "module_code" : "SS0008",
                    "module_link" : "manage-devices",
                    "module_name" : "Manage Devices",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0009",
                    "module_link" : "manage-hubs",
                    "module_name" : "Manage Hubs/Stores",
                    "module_icon" : "charging-station"
                },
                {
                    "module_code" : "SS0010",
                    "module_link" : "manage-geofence",
                    "module_name" : "Manage Geofence",
                    "module_icon" : "map"
                },
                {
                    "module_code" : "SS0011",
                    "module_link" : "manage-labels",
                    "module_name" : "Manage Labels",
                    "module_icon" : "tags"
                },
                {
                    "module_code" : "SS0012",
                    "module_link" : "manage-users",
                    "module_name" : "Manage Users",
                    "module_icon" : "user-shield"
                }
            ],
            "mmenu_code" : "SM05",
            "mmenu_color": "text-success",
            "mmenu_icon" : "cog",
            "mmenu_link" : null,
            "mmenu_name" : "Settings"
        },
        {
            "items" : [],
            "mmenu_code" : "SM06",
            "mmenu_color": "text-info",
            "mmenu_icon" : "id-card",
            "mmenu_link" : "manage-riders",
            "mmenu_name" : "Manage Riders"
        },
        {
            "items" : [],
            "mmenu_code" : "SM07",
            "mmenu_color": "text-danger",
            "mmenu_icon" : "exchange-alt",
            "mmenu_link" : "transfer-devices-hubs",
            "mmenu_name" : "Transfer Devices (Hubs)"
        },
        {
            "items" : [],
            "mmenu_code" : "SM08",
            "mmenu_color": "text-success",
            "mmenu_icon" : "car-battery",
            "mmenu_link" : "transfer-devices-rider",
            "mmenu_name" : "Transfer Devices (Rider)"
        }
    ]
}