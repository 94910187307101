import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ToastServiceService } from 'src/app/services/toastService/toast-service.service';
import { config } from 'src/environments/config';

@Component({
  selector: 'app-side-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
  user_name: string = '';

  notifications: any = [];
  newNotifications: any = [];
  unread_count: number = 0;
  showNotifications: boolean = false;
  count: number = 0;
  
  //interval: any;

  constructor(
    private actModal: NgbActiveModal,
    public notificationService: NotificationsService,
    private toastService: ToastServiceService
  ) { }

  ngOnInit(): void {
    this.notificationService.showNotifications.subscribe((value) => {
      this.showNotifications = value;
    });
    this.notificationService.count.subscribe((value) => {
      this.count = value;
    });
    var data = localStorage.getItem(config.license_code);
    if(data !== null){
      var LocalStorage: any = JSON.parse(data)
      this.user_name = LocalStorage['user_details']['user_name'];
    };

    this.notificationService.getNotifications();
    
    this.notificationService.notifications.subscribe((data) => {
      this.unread_count = 0;
      this.showNotifications = false;
      this.notifications = data;
      this.notifications.map((record: any) => {
        if(record.read_flag == 'N')
          this.unread_count++;
      })
      this.showNotifications = true;
    });

    
    // this.interval = setInterval(()=> {
    //   this.notificationService.getNewNotifications();
    // },1*10*1000);

    // this.notificationService.newNotifications.subscribe((data) => {
    //   this.newNotifications = data;
    //   if(this.newNotifications.length){
    //     this.showNotifications = false;
    //     this.notifications = this.notifications.concat(this.newNotifications);
    //     this.newNotifications.map((record: any) => {
    //       if(record.read_flag == 'N')
    //         this.unread_count++;
    //     })
    //     this.showNotifications = true;
    //     this.toastService.show('Notifications',this.newNotifications.length + ' new notification','bg-info' ,{});
    //   }
    // });
  };
  deleteAll():void{
    this.notificationService.deleteAll();
  }
  closeSidePanel(){
    this.actModal.dismiss();
  };

  ngOnDestroy(): void{
    //clearInterval(this.interval);
  };
}
