export const trackingFilters : any = [
    {
        field_desc:  "Device Name",
        data_type :  "text",
        field_name:  "device_name",
        input_type:  "input",
        sort_seqno:  1,
        placeholder: "Enter Device Name",
        default: "",
        options: null
    },
    {
        field_desc: "Battery Status",
        data_type :"text",
        field_name: "cstt",
        input_type: "select",
        sort_seqno: 2,
        placeholder:"Select",
        input_link: null,
        default: "",
        options:[
            {
                code: '+',
                name:"Charging"
            },
            {
                code: '-',
                name:"Discharging"
            },
            {
                code: 'I',
                name:"Idle"
            }
        ]
    },
    {
        field_desc:  "Label",
        data_type :  "text",
        field_name:  "label",
        input_type:  "select",
        sort_seqno:  3,
        placeholder: "Select",
        input_link:  "sticklabels/select",
        options:[]
    },
    {
        field_desc:  "Geolocation",
        data_type :  "text",
        field_name:  "fence",
        input_type:  "select",
        sort_seqno:  4,
        placeholder: "Select",
        input_link:  "globalfence/select",
        default: "",
        options:[]
    },
    {
        field_desc:  "Hub",
        data_type :  "text",
        field_name:  "store",
        input_type:  "select",
        sort_seqno:  5,
        placeholder: "Select",
        input_link:  "storagehubs/select",
        default: "",
        options:[]
    },
    {
        field_desc: "SOC",
        data_type :"number",
        field_name: "soc",
        input_type: "combo",
        sort_seqno: 6,
        placeholder:null,
        default: 0,
        operators:[
            {
                code: "<",
                name: "<"
            },
            {
                code: ">",
                name: ">"
            },
            {
                code: "=",
                name: "="
            },
            {
                code: "<=",
                name: "<="
            },
            {
                code: ">=",
                name: ">="
            }
        ]
    },
    {
        field_desc: "SOH",
        data_type :"number",
        field_name: "soh",
        input_type: "combo",
        sort_seqno: 7,
        placeholder:null,
        default: 0,
        operators:[
            {
                code: "<",
                name: "<"
            },
            {
                code: ">",
                name: ">"
            },
            {
                code: "=",
                name: "="
            },
            {
                code: "<=",
                name: "<="
            },
            {
                code: ">=",
                name: ">="
            }
        ]
    },
    {
        field_desc: "Cycle Count",
        data_type :"number",
        field_name: "cycle_count",
        input_type: "combo",
        sort_seqno: 8,
        placeholder:null,
        default: 0,
        operators:[
            {
                code: "<",
                name: "<"
            },
            {
                code: ">",
                name: ">"
            },
            {
                code: "=",
                name: "="
            },
            {
                code: "<=",
                name: "<="
            },
            {
                code: ">=",
                name: ">="
            }
        ]
    },
    {
        field_desc: "Speed",
        data_type :"number",
        field_name: "speed",
        input_type: "combo",
        sort_seqno: 9,
        placeholder:null,
        default: 0,
        operators:[
            {
                code: "<",
                name: "<"
            },
            {
                code: ">",
                name: ">"
            },
            {
                code: "=",
                name: "="
            },
            {
                code: "<=",
                name: "<="
            },
            {
                code: ">=",
                name: ">="
            }
        ]
    }
];

export const advanceFilters : any = [
    {
        field_desc: 'All Devices',
        field_name: 'all',
        sort_seqno: 1
    },
    {
        field_desc: 'Hub Devices',
        field_name: 'hub',
        sort_seqno: 2
    },
    {
        field_desc: 'Field Devices',
        field_name: 'field',
        sort_seqno: 3
    },
    {
        field_desc: 'Hub Devices - Charged',
        field_name: 'hub_charged',
        sort_seqno: 4
    },
    {
        field_desc: 'Hub Devices - Discharged',
        field_name: 'hub_discharged',
        sort_seqno: 5
    },
    {
        field_desc: 'Hub Devices - Charging',
        field_name: 'hub_charging',
        sort_seqno: 6
    },
    {
        field_desc: 'Hub Devices - Idle',
        field_name: 'hub_idle',
        sort_seqno: 7
    },
    {
        field_desc: 'Field Devices - Discharged',
        field_name: 'field_discharged',
        sort_seqno: 8
    },
    {
        field_desc: 'Field Devices - Under Use',
        field_name: 'field_use',
        sort_seqno: 9
    },
    {
        field_desc: 'Field Devices - Idle',
        field_name: 'field_idle',
        sort_seqno: 10
    },
    {
        field_desc: 'Field Devices - Charging',
        field_name: 'field_charging',
        sort_seqno: 11
    }
];
export const deviceFilters :any=[
    {
        field_desc:  "Device Name",
        data_type :  "text",
        field_name:  "device_name",
        input_type:  "input",
        sort_seqno:  1,
        placeholder: "Enter Device Name",
        default: "",
        options: null
    },
    {
        field_desc:  "Label",
        data_type :  "text",
        field_name:  "label",
        input_type:  "select",
        sort_seqno:  3,
        placeholder: "Select",
        input_link:  "sticklabels/select",
        options:[]
    },
    {
        field_desc:  "Geofence",
        data_type :  "text",
        field_name:  "fence",
        input_type:  "select",
        sort_seqno:  4,
        placeholder: "Select",
        input_link:  "globalfence/select",
        default: "",
        options:[]
    },
    {
        field_desc:  "Hub",
        data_type :  "text",
        field_name:  "store",
        input_type:  "select",
        sort_seqno:  5,
        placeholder: "Select",
        input_link:  "storagehubs/select",
        default: "",
        options:[]
    },
    {
        field_desc: "Status",
        data_type :"text",
        field_name: "status",
        input_type: "select",
        sort_seqno: 6,
        placeholder:"Select",
        input_link: null,
        default: "",
        options:[
            {
                code: 'A',
                name:"Active"
            },
            {
                code: 'I',
                name:"Inactive"
            }
        ]
    }

]

