import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { Component, OnInit } from '@angular/core';
import { SidepanelsService } from 'src/app/services/sidepanels/sidepanels.service';
import { Router } from '@angular/router';
import { LoginStatusService } from 'src/app/services/login-status/login-status.service';
import { config } from 'src/environments/config';
import { filter } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/services/http-request/http-request.service';
import { ToastServiceService } from 'src/app/services/toastService/toast-service.service';
import { apiURL } from 'src/app/models/apis';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user_name: string = '';
  full_name:string='';
  short_name:string='';
  screenOrientation: any;

  deviceSearch: string = '';

  device_list: any = [];
  notificationOptions: any[];
  alertOptions: any[];
  userOptions: any[];
  notification_count: number =0;
  constructor(
    public sidepanel: SidepanelsService,
    private _router: Router,
    public sharedService: LoginStatusService,
    public subscription: SubscriptionService,
    private httpService: HttpRequestService,
    public toastService: ToastServiceService,
    public subscribe: SubscriptionService,
    public notificationService: NotificationsService,

  ) {}

  ngOnInit(): void {
    var data = localStorage.getItem(config.license_code);
    this.notificationOptions = [
      {label: 'Notification 1', value: 'notification-1'},
      {label: 'Notification 2', value: 'notification-2'},
      {label: 'Notification 3', value: 'notification-3'}
    ];
    this.alertOptions = [
      {label: 'Alert 1', value: 'alert-1'},
      {label: 'Alert 2', value: 'alert-2'},
      {label: 'Alert 3', value: 'alert-3'}
    ];
    this.userOptions = [
      {label: 'Profile', routerLink: '/profile'},
      {label: 'Log Out', command: () => this.logout()}
    ];
    if(data !== null){
      var LocalStorage: any = JSON.parse(data)
      this.user_name = LocalStorage['user_details']['user_name'];
      this.full_name=LocalStorage['user_details']['full_name'];
      var firstName=LocalStorage['user_details']['first_name'];
      var lastName=LocalStorage['user_details']['last_name'];
      var first_letter = firstName[0];
      var last_letter:any;
      if(lastName.length > 0){
        last_letter = lastName[0];
      }
      else{
        last_letter= ''
      }
      this.short_name = first_letter + last_letter

    }
    this.screenOrientation = this.subscription.display_mode;

    this.notificationService.count.subscribe((value) => {
      this.notification_count = value;
    });
  }

  searchDevice(): void{
    if(this.deviceSearch == ''){
      this.device_list = [];
      this.subscribe.showList = false;
    }
    else{
      var payload = {
        device_name: this.deviceSearch
      }
  
      this.httpService.request(apiURL['deviceList'], payload).subscribe(
        (response: any) => {      
          if(response['status'] === 'success'){
            this.device_list = response.data;
            this.subscribe.showList = true;
          } else {
            if (response['success'] === false) {
              this.sharedService.sessionExpired('session')
            }
            else {
              this.toastService.show('Data', 'No data present', 'bg-danger', {});
            }
          }
        }
      )
    }
    
  }

  openDetails(id: string):void {
    this._router.navigateByUrl('/skip', {skipLocationChange: true}).then(()=>
    this._router.navigateByUrl('/device-details/'+ id));
    
  }
  logout() {
    
  }
}
