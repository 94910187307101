import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {ToastServiceService} from 'src/app/services/toastService/toast-service.service'
import { config } from '../../../environments/config';
import { SubscriptionService } from '../subscription/subscription.service';
import { NotificationsService } from '../notifications/notifications.service';
@Injectable({
  providedIn: 'root'
})
export class LoginStatusService {
  loginStatus: number = 0;
  license_code: any =  {
    user_details : '',
    web_token    : '',
    login_status : '',
  };

  constructor(
    private _router: Router,
    public toastService: ToastServiceService,
    private subscribe: SubscriptionService
  ) { }

  updateDetails(value: any){
    //called when user logs in 
    this.license_code['user_details'] = value['data']
    this.license_code['web_token'] = value['token']
    this.license_code['login_status'] = '1'
    this.loginStatus = 1

    this.subscribe.portal_type = value.data.portal_type
    localStorage.setItem(config.license_code ,JSON.stringify(this.license_code))
  };

  sessionExpired(type: any){
    localStorage.removeItem(config.license_code);
    sessionStorage.removeItem(config.license_code);
    this.subscribe.hf_status = false;
    this.loginStatus = 0
    if(type === 'session')
      this.toastService.show('Session Expired','Please Relogin','bg-danger' ,{});   
    this._router.navigate(['/login']);
  }
}
