<div class="table-responsive scroll-table bg-white">
    <table class="table table-bordered basic-table">
        <thead>
            <tr class="row m-0">
                <ng-container  *ngFor="let head of table_data.head | orderBy: 'sort_seqno'">
                    <th class="col-1" *ngIf="head.field == 'id'">{{head.name}}</th>
                    <th class="col-2" *ngIf="head.field == 'timestamp' || head.field == 'status' || head.field == 'device_id'">{{head.name}}</th>
                    <th class="col-3" *ngIf="head.field == 'string' || head.field == 'msg_date' || head.field == 'msg_time'">{{head.name}}</th>
                    <th class="col-4" *ngIf="head.field == 'cmd_value'">{{head.name}}</th>
                    <th class="col-6" *ngIf="head.field == 'data' || head.field == 'cmd_data'">{{head.name}}</th>
                    <th class="col-10" *ngIf="head.field == 'remarks'">{{head.name}}</th>

                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr class="row m-0" *ngFor="let data of table_data.data" >
                <ng-container *ngFor="let head of table_data.head | orderBy: 'sort_seqno'">
                    <td class="col-1" *ngIf="head.field == 'id'">{{data[head['field']]}}</td>
                    <td class="col-2" *ngIf="head.field == 'timestamp' || head.field == 'status' || head.field == 'device_id'">{{data[head['field']]}}</td>
                    <td class="col-3" *ngIf="head.field == 'string' || head.field == 'msg_date' || head.field == 'msg_time'">{{data[head['field']]}}</td>
                    <td class="col-4" *ngIf="head.field == 'cmd_value'">{{data[head['field']]}}</td>
                    <td class="col-6" *ngIf="head.field == 'data' || head.field == 'cmd_data'">{{data[head['field']]}}</td>
                    <td class="col-10" *ngIf="head.field == 'remarks'">{{data[head['field']]}}</td>

                </ng-container>
            </tr> 
        </tbody>
    </table> 
</div>