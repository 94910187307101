import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { FilterPanelComponent } from './filter-panel.component';

@NgModule({
  declarations: [
    FilterPanelComponent,
  ],
  imports: [
    SharedModule
  ],
  exports: [
    FilterPanelComponent
  ]
})
export class FilterPanelModule { }
