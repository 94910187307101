export const apiPayload: any = {
    sidemenu : {
        data_owner: 'USR'
    },
    //Device Details
    iot_data:{
        tag_head:'IOT'
    },
    bms_data:{
        tag_head:'BMS'
    },
    //ALERTS PAYLOAD
    warningList:{
        warning_head:'WARNING'
    },
    errorList:{
        warning_head:'ERROR'
    },
    otherAlerts:{
        warning_head:'TMPALERT'
    },
    //Settings
    userPayload : { 
        id: 'SUBUSERS'
    },
    //REPORT PAYLOAD
    reportsList : { 
        report_type: 'REP',
        data_owner:'USR',
        apln_module:'APP'
    },
    devReport : { 
        report_type: 'REP',
        data_owner:'USR',
        apln_module:'DEV'
    },
    graphsList : {
        report_type: 'GRAPH'
    },
    //DEVICES PAYLOAD
    emuronDevices:{
        device_owner:'EMURON'
    },
    otherDevices:{
        device_owner:'OTHERS'
    },
    nonIot:{
        device_owner:'NONIOT'
    }
    
}