import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpRequestService } from '../../services/http-request/http-request.service'
import { apiURL } from '../../models/apis';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() table_data: any; 
  @Input() table_details:any;
  @Output() refreshContent: EventEmitter<any> = new EventEmitter();
  
  payload={
    id:''
  }
  constructor(private httpService: HttpRequestService,) { }
 
  ngOnInit(): void {
  }
  markAsRead(id){ 
    this.payload.id=id;

    this.httpService.request(apiURL['alerts'].setread,this.payload).subscribe((response : any )=> {
      if(response['status'] === 'success'){
          this.refreshContent.emit()
      }
    })
  }
}
 