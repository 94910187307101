<div class="side-panel">
    <div class="panel-header">
        <span class="header">Filters</span>
        <button class="btn btn-default close" (click)="closeSidePanel()">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <div class="panel-body2">
        <form class="row formFilter" *ngIf="showFilters">
            <div class="col-12 form-group " *ngFor = " let filter of filters | orderBy: 'sort_seqno' ">
                <label class="form-items">{{filter.field_desc}}</label>
                <input class="input-group " [type]="filter.data_type" class="form-control" [name]="filter.field_name" [placeholder]="filter.placeholder" [(ngModel)]="subscribe.filter_payload[filter.field_name].argument"  *ngIf="filter.input_type === 'input'">
                <ng-select
                  [placeholder] = "filter.placeholder"
                  [name] = "filter.field_name" 
                  *ngIf = "filter.input_type === 'select'"
                  bindLabel = "name" 
                  bindValue ="code"
                  [(ngModel)] = "subscribe.filter_payload[filter.field_name].argument"
                  [clearable] = false
                  >
                  <ng-option value="">Select</ng-option>
                  <ng-option *ngFor="let item of filter['options'] | orderBy : ['name']" [value]="item.code">{{item.name}}</ng-option>
                </ng-select>
             
                <div class="combo-input" *ngIf = "filter.input_type === 'combo'">
                    <ng-select 
                      [placeholder] = "filter.placeholder"
                      name = "{{filter.field_name + '_op' }}"
                      bindLabel = "name" 
                      bindValue = "code"
                      [(ngModel)] = "subscribe.filter_payload[filter.field_name].operator"
                      [clearable] = false
                    >
                      <ng-option value="">Select</ng-option>
                      <ng-option *ngFor="let item of filter['operators'] | orderBy : ['name']" [value]="item.code">{{item.name}}</ng-option>
                    </ng-select>
                    <input class="form-control input-group" [type]="filter.data_type" class="form-control" name="{{filter.field_name + '_ar' }}" [placeholder]="filter.placeholder" [(ngModel)]="subscribe.filter_payload[filter.field_name].argument">
                </div>
            </div>
            <button type="button" class="btn text-primary pl-0" (click)="openShowAdv()" *ngIf="showButton">Advanced Filters</button>
            <div class="advance-filter-container" *ngIf="showAdvFilters">
              <div class="form-check" *ngFor="let filter of aFilters | orderBy: 'sort_seqno'">
                <input class="form-check-input" type="radio" name="aFilter" [id]="filter.field_name" [value]="filter.field_name" [(ngModel)] = "aFilter">
                <label class="form-check-label" [for]="filter.field_name">
                  {{filter.field_desc}}
                </label>
              </div>
            </div>
        </form>
    </div>
    <div class="panel-footer">
        <button type="button" class="btn btn-secondary"(click)="closeSidePanel()">Close</button>
        <button type="button" class="btn btn-primary" (click) = "filterData()">Search</button>
        <button type="button" class="btn btn-danger" (click)="clearFilter()">Clear Filters</button>
    </div>
</div>