import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-breach-panel',
  templateUrl: './breach-panel.component.html',
  styleUrls: ['./breach-panel.component.scss']
})

export class BreachPanelComponent implements OnInit {
  @Input() data: any;

  table_details : any = {
    showRead: false,
    striped: true
  }
  
  constructor(
    private actModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    console.log("this is :",this.table_details)

  }

  closeSidePanel(){
    this.actModal.dismiss();
  }

}
