export const apiURL: any = {
    config: 'managelogin/config',
    login: 'managelogin/login',
    getUserData: 'manageusers/',
    changePassword: 'managelogin/edit-password', 
    checkUsername: 'manageusers/check-username',
    deviceList: 'regddevices/select',
    menu: 'managelogin/menu',
    //HEADER FILTERS
    firstBuyers: { 
        base: 'firstbuyers/',
        data: 'firstbuyers/object'
    },

    //Change Password
    pwd:{
        validate:'manageusers/verify-password',
        update:'manageusers/edit-password'
    },
    regdDealers: {
        base: 'regddealers/', 
        data: 'regddealers/object'
    },
    //Profile
    profile:{
        base:'listofusers/',
        data:'firstbuyers/object'
    },

    //DASHBOARD
    dashboard: {
        analysis: 'dbdashboard/analysis',
        breached: 'regddevices/breached',
        devices_loc: 'regddevices/locations'
    },

    //TRACKING
    tracking: {
        regDevices: 'regddevices/latestdata',
        devices: 'regddevices/locations',
        hub: 'storagehubs/locations',
        last_sync: '',
        filters: ''
    },

    //Device Details 
    device_details: {
        base: 'regddevices/deviceinfo',
        overview: 'regddevices/overview',
        battery_data: 'regddevices/batterydata',
        timeline: 'regddevices/geotrack',
        remote_access: '',
        deviceReport:'apprreports/prepreport'
    },
 
    //Alerts
    alerts: {
        geofence: 'warninglogs/geofence',
        others: 'warninglogs/',
        setread:"warninglogs/setread"
    },
    
    //REPORTS & GRAPHS
    reportsList: {
        base: 'reportslist/',
        data: 'reportslist/object'
    },
    rParameters: {
        base: 'rparameters/',
        data: 'rparameters/object'
    },

    //SETTINGS - MANAGE DEVICES
    activatedDevices: {
        base: 'editdevices/',
        data: 'editdevices/object'
    },
    newDevices: {
        base: 'seeddevices/',
        data: 'seeddevices/object'
    },
    
    hubs: {
        base:'mviewstores/',
        data: 'mviewstores/object'
    },
    inventories:{
        base:'regddevices/inventories'
    },

    globalfence:{
        base: 'mviewfences/',
        data: 'mviewfences/object'
    },

    labels:{
        base: 'mviewlabels/',
        data: 'mviewlabels/object'
    },

    users:{
        base:'listofusers/',
        data:'listofusers/object',
        available:'userdevices/thealldevices',
        assigned:'userdevices/lessordevices',
        assignDevices:'userdevices/assigndevices',
        deAssignDevices:'userdevices/removedevices',
        upload:'userdevices/upload-csv',
    },

    eoemDevices: {
        base: 'eoemdevices/',
        data: 'eoemdevices/object'
    },
    //Riders
    riders:{
        base: 'mviewdriver/',
        data: 'mviewdriver/object',
        sendOTP:'driverlogin/sendOTP',
        verifyOTP:'driverlogin/verifyOTP',
        updateMob:'regddrivers/update-mobile'
    },

    //Transfer Devices- HUb
    trfDevHub:{
        hubs:'storagehubs/select',
        devices:'regddevices/inventories',
        transfer: 'txnregister/tfr2hub',
        upload: 'txnregister/tfr2hubUpload'
    },
   
    //Transfer Devices- Rider
    trfDevRider:{
        inventory:'regddevices/inventories/',
        rider:'regddrivers/rider',
        drvr_devices:'regddevices/drvrdevices',
        transferDevices:'txnregister/swaptxn'
    },

    //Notifications
    notifications: {
        getAll: 'ucbulletins/getAll',
        getNew: 'ucbulletins/getNew',
        setRead: 'ucbulletins/setRead',
        delete: 'ucbulletins/delete',
        setReadAll: 'ucbulletins/setReadAll',
        deleteAll: 'ucbulletins/deleteAll'
    },

    //Remote Access
    remoteAccess: {
        base: 'regddevices/remoteaccess',
        immobilize: 'tmatactions/insert'
    },

    terminal: {
        device: 'terminal/devicelist',
        details: 'terminal/logs',
        cmd: 'terminal/addcommand'
    },

    testdata: {
        device: 'terminal/devicelist',
        data: 'terminal/testdata'
    },
    newapi: {
        getAll:'devices/get-all'
    }

}