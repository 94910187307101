import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { config } from 'src/environments/config';

var stored_data = localStorage.getItem(config.license_code);
var license_code = JSON.parse(stored_data);
var page = 'dashboard'
if(license_code){
  page = (license_code.user_details.default_page) ? license_code.user_details.default_page : 'dashboard'
}


const routes: Routes = [
  { 
    path: '',
    pathMatch: 'full' ,
    redirectTo: page 
  },
  { 
    path: 'skip',
    redirectTo: ''
  },
  {
    path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },

  {
    path: 'dashboard', 
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), 
    data:{
      showFilter:true,
      map_data:{
        entity: 'D',
        device: {
          entity: 'device',
          marker_label: true,
          info_window: 0,
          data:[]
        }
      }
    }
  },

  // Tracking
  {
    path: 'list-view', loadChildren: () => import('./pages/tracking-list/tracking-list.module').then(m => m.TrackingListModule),
    data:{
      filter:'trackingFilter'
    }
    
  },
  {
    path: 'map-view', 
    loadChildren: () => import('./pages/tracking-map/tracking-map.module').then(m => m.TrackingMapModule),
    data:{
       map_data:{
        entity: 'D&H',
        device: {
          entity: 'device',
          marker_label: true,
          info_window: 1,
          data:[]
        },
        hub: {
          entity: 'hub',
          marker_label: false,
          info_window: 2,
          data:[]
        }
      },
      filter:'trackingFilter'
    }
  },
  
  {
    path: 'device-details', 
    loadChildren: () => import('./pages/device-details/device-details.module').then(m => m.DeviceDetailsModule),
    
  },

  // Alerts
  {
    path: 'geofencing-alerts',
    loadChildren: () => import('./pages/alerts/alerts.module').then(m => m.AlertsModule),
    data:{
      count:1,
      breadcrumb:'Geofence Breach Alerts',
      primary:{ icon:'route',title:'Geofence Breach Alerts',color:'#17a2b8',endpoint:'geofence',payload:null},
      table_details: {showRead: false}
    }
  },
  {
    path: 'bms-alerts',
    loadChildren: () => import('./pages/alerts/alerts.module').then(m => m.AlertsModule),
    data:{
      count:2,
      breadcrumb:'BMS',
      primary:{ icon:'exclamation-triangle' ,title:'Warnings',color:'#ffc107',endpoint:'others',payload:'warningList'
      },
      secondary:{icon:'times', title:'Errors',color:'#dc3545',endpoint:'others',payload:'errorList'},
      table_details: {showRead: false}
    }
  },
  {
    path: 'other-alerts',
    loadChildren: () => import('./pages/alerts/alerts.module').then(m => m.AlertsModule),
    data:
    { count:1,
      breadcrumb:'Other Alerts',
      primary:{ icon:'exclamation-circle',title:'Other Alerts',color:'#0d6efd',endpoint:'others',payload:'otherAlerts'},
      table_details: {showRead: false}
    }
  },
  //Settings
   
  { 
    path: 'manage-devices',
    loadChildren: () => import('./pages/manage-devices/manage-devices.module').then(m => m.ManageDevicesModule),
    data:{
      title:'Manage Devices',
      emuron:{endpoint:'activatedDevices',payload:'emuronDevices', formtype: 'form', updatemobile: false, changepwd: false,activate:false, pageSize:10, modaltitle: 'Device'},
      other:{endpoint:'activatedDevices',payload:'otherDevices', formtype: 'form', updatemobile: false, changepwd: false,activate:false, pageSize:10, modaltitle: 'Device'},
      noniot:{endpoint:'activatedDevices',payload:'nonIot', formtype: 'form', updatemobile: false, changepwd: false,activate:false, pageSize:10, modaltitle: 'Device'},
      new:{endpoint:'newDevices', payload:null, formtype: 'form', updatemobile: false, changepwd: false,activate:true, pageSize:10, modaltitle: 'Device'},
      user: {handle: false, source:'', target: ''},
      filter:'deviceFilter'
    }
  },
  {
    path:'manage-hubs',
    loadChildren:()=>import('./pages/manage-master/manage-master.module').then(m=>m.ManageMasterModule),
    data:{
      title:'Manage Hubs/Stores',endpoint:'hubs',payload:null,action:false,
      modaltitle: 'Hub',
      formtype: 'map',
      updatemobile: false,
      changepwd: false,
      activate:false, 
      pageSize:10,
      showFilter:false,
      user: {handle: true, source:'store_name', target: 'parent_user'},
      mapview: {show: true, endpoint: ''},
      map_data:{
        entity: 'H',
        hub : {
          entity: 'hub',
          marker_label: true,
          info_window: 0,
          data:[]
        }
      },
      upload: false
    }
  },
  {
    path:'manage-geofence',
    loadChildren:()=>import('./pages/manage-master/manage-master.module').then(m=>m.ManageMasterModule),
    data:{
      title:'Manage Geofence',endpoint:'globalfence',payload:null,action:false,
      modaltitle: 'Place',
      formtype: 'map',
      updatemobile: false,
      changepwd: false,
      activate:false,
      pageSize:10,
      user: {handle: false, source:'', target: ''},
      mapview: {show: false, endpoint: ''},
      upload: false
    }
  },
  {
    path:'manage-labels',
    loadChildren:()=>import('./pages/manage-master/manage-master.module').then(m=>m.ManageMasterModule),
    data:{
      title:'Manage Labels',endpoint:'labels',payload:null,action:false,
      modaltitle: 'Label',
      formtype: 'form',
      updatemobile: false,
      changepwd: false,
      activate:false,
      pageSize:10,
      user: {handle: false, source:'', target: ''},
      mapview: {show: false, endpoint: ''},
      upload: false
    }
  },
  {
    path:'manage-users',
    loadChildren:()=>import('./pages/manage-users/manage-users.module').then(m=>m.ManageUsersModule),
    data:{
      title:'Manage Users',endpoint:'users',payload:'userPayload',action:true,
      formtype: 'form',
      modaltitle: 'User',
      updatemobile: false,
      changepwd: true,
      activate:false,
      pageSize:5,
      user: {handle: false, source:'', target: ''}
    }
  },

  {
    path: 'eoemDevices', 
    loadChildren: () => import('./pages/manage-master/manage-master.module').then(m => m.ManageMasterModule),
    data:{
      title:'Device List',endpoint:'eoemDevices',payload:null,action:false,
      modaltitle: 'Device',
      formtype: 'form',
      updatemobile: false,
      changepwd: false,
      activate:false,
      pageSize:10,
      user: {handle: false, source:'', target: ''},
      mapview: {show: false, endpoint: ''},
      upload: true
    }
  },
  //Manage Riders
  {
    path:'manage-riders',
    loadChildren:()=>import('./pages/manage-master/manage-master.module').then(m=>m.ManageMasterModule),
    data:{
      title:'Manage Riders',endpoint:'riders',payload:null,
      modaltitle: 'Rider',
      formtype: 'form',
      updatemobile: true,
      changepwd: false,
      activate:false,
      pageSize:10,
      user: {handle: false, source:'', target: ''},
      mapview: {show: false, endpoint: ''},
      upload: false
    }
  },

  // Transfer Devices
  {
    path: 'transfer-devices-rider',
    loadChildren: () => import('./pages/transfer-devices-rider/transfer-devices-rider.module').then(m => m.TransferDevicesRiderModule)
  },
  {
    path: 'transfer-devices-hubs',
    loadChildren: () => import('./pages/trf-devices/trf-devices.module').then(m => m.TrfDevicesModule),
    data:{
      table_details: {showRead: false}
    }
  },

  { path: 'reports'         , loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) , data: {page: 'View Reports', type: 'REP'  , list: 'reportsList'} },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'change-password' , loadChildren: () => import('./pages/changepassword/changepassword.module').then(m => m.ChangepasswordModule) },
  { path: 'graphs'          , loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) , data: {page: 'View Graphs', type: 'GRAPH', list: 'graphsList'} },
  { path: 'terminal', loadChildren: () => import('./pages/terminal/terminal.module').then(m => m.TerminalModule) },
  { path: 'testdata', loadChildren: () => import('./pages/testdata/testdata.module').then(m => m.TestdataModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always' , useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
