import { NgModule } from '@angular/core';
import { CellDisplayPipe } from './cell-display.pipe';

@NgModule({
  declarations: [
    CellDisplayPipe
  ],
  imports: [],
  exports: [
    CellDisplayPipe
  ]
})
export class CellDisplayModule { }
