<div class="h-100">
    <ng-container  *ngIf="data.length > 0">
        <div class="alerts" *ngFor="let notification of data | orderBy : ['push_flag' , 'read_flag' , '-warning_date']"  [ngClass] = "{'bg-light' : notification.read_flag == 'Y'}">
            <button class="btn btn-default alert-icon" >
                <fa-icon icon="exclamation-triangle" class="text-success"></fa-icon>
            </button>
            <div class="alert-content">
                <span class="alert-title">
                    {{notification.warning_text}}
                </span>
                <br>
                <span>
                    {{notification.detail_log}}
                </span>
                <br>
                <span class="text-primary">
                    {{notification.warning_date | timeAgo}}
                </span>
            </div>
            <div class="notification-actions">
                <button class="btn no-btn" (click)="notificationService.setRead(notification.id)" *ngIf="notification.read_flag == 'N'">
                    <fa-icon class="ml-auto text-muted" icon="marker" ngbTooltip="Mark Read"></fa-icon>
                </button>
                <button class="btn no-btn" (click)="notificationService.delete(notification.id)">
                    <fa-icon class="ml-auto text-muted" icon="trash" ngbTooltip="Clear"></fa-icon>
                </button>
            </div>
        </div> 
    </ng-container>
    
    <div class="no-data-container" *ngIf="data.length == 0">
        <h5>No Notifications Available</h5>
    </div>
</div>