import { Component, OnInit } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { apiURL } from 'src/app/models/apis';
import { apiPayload } from 'src/app/models/payload';
import { sidemenuItems } from 'src/app/models/sidemenu';
import { HttpRequestService } from 'src/app/services/http-request/http-request.service';
import { LoginStatusService } from 'src/app/services/login-status/login-status.service';
import { SidepanelsService } from 'src/app/services/sidepanels/sidepanels.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  providers: [NgbTooltipConfig]
})
export class SidemenuComponent implements OnInit {

  menuCollapsed: boolean = false;
  selectedOption: string = '';

  menus: any = sidemenuItems.menus;
  poweredBy: any = sidemenuItems.poweredBy;
  
  showMenu: boolean = false;
  constructor(
    public sidepanel: SidepanelsService,
    tooltipConfig: NgbTooltipConfig,
    public subscribe: SubscriptionService,
    private httpService: HttpRequestService,
    private sharedService: LoginStatusService
  ) { 
    tooltipConfig.placement = 'right';
    tooltipConfig.container = 'body'
  }

  ngOnInit(): void {
    this.sidepanel.collapsed.subscribe((value)=>{
      this.menuCollapsed = value;
      this.getSidemenu();
    })
  };

  getSidemenu(): void {
    this.httpService.request(apiURL['menu'], apiPayload['sidemenu']).subscribe((response : any) => {
      if (response['status'] === 'success') {
          if(response['data'].length){
            this.menus = response.data;
          }else{
            this.menus = sidemenuItems.menus;
          }
          this.showMenu = true;
      }else {
        if(response['success'] === false)
          this.sharedService.sessionExpired('session')    
      }
    })
  };

  openSubmenu(id: string,items: any = null): void{
    if(items.length && this.selectedOption !== id){
      this.selectedOption = id;
    }else{
      this.selectedOption = ''
    }
  };

  collapseMenu(source: string): void{
    if(this.subscribe.display_mode == 'PM' || this.subscribe.display_mode == 'LM'){
      this.sidepanel.toggleSidemenu()
    }

    if(source == 'M')
      this.selectedOption = "";
  };
}
