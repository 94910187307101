import { Injectable } from '@angular/core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { BreachPanelComponent } from 'src/app/components/breach-panel/breach-panel.component';
import { FilterPanelComponent } from 'src/app/components/filter-panel/filter-panel.component';
import { NotificationPanelComponent } from 'src/app/components/notification-panel/notification-panel.component';
import { SubscriptionService } from "../subscription/subscription.service"

@Injectable({
  providedIn: 'root'
})
export class SidepanelsService {

  modalRef : any;
  modalOptions: Object = {windowClass: 'custom-modal-window',modalDialogClass: 'custom-side-panel-dialog'};

  constructor(
    private modalService: NgbModal,
    public subscription:SubscriptionService
  ) { }
  private _collapsed : BehaviorSubject<any> = new BehaviorSubject(false)
  collapsed = this._collapsed.asObservable()


  toggleSidemenu(){
    this._collapsed.next(!this._collapsed.value)
  }

  toggleSidePanel(type: string, data: any = []){
    switch(type) {
      case 'NOTIFICATION':
        this.modalRef = this.modalService.open(NotificationPanelComponent, this.modalOptions);
        break;
      case 'BREACH':
        this.modalRef = this.modalService.open(BreachPanelComponent, this.modalOptions);
        this.modalRef.componentInstance.data = data;
        break;
      case 'FILTERS':
          this.modalRef = this.modalService.open(FilterPanelComponent, this.modalOptions);
          this.modalRef.componentInstance.data = data;
          this.modalRef.componentInstance.filterContent.subscribe((filter: any) => {
            this.subscription.updateFilterData(filter)
          })
        break;
      default:
        break;
    }
  }
}
