
<div class="table-responsive scroll-table bg-white">
    <table class="table" [ngClass]="{'table-striped': table_details.striped}">
        <thead>
            <tr>
                <ng-container  *ngFor="let head of table_data.head | orderBy: 'sort_seqno' ">
                    <th scope="col" class="fit" *ngIf="head.field!='id' && head.field!='read_flag' ">{{head.name}}</th>
                </ng-container>
                <th scope="col" class="fit" *ngIf="table_details.showRead">Read Status</th>    
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of table_data.data" >
                <ng-container *ngFor="let head of table_data.head | orderBy: 'sort_seqno'">
                    <td *ngIf="head.field!=='id' && head.field!='read_flag'" >{{data[head['field']]}}</td>
                </ng-container>
                <td *ngIf="table_details.showRead">
                    <button class="btn btn-primary" *ngIf="data.read_flag === 'N' " (click)="markAsRead(data.id)">Mark as Read
                    </button>
                    <div class="read bg-success" *ngIf="data.read_flag === 'Y' ">Read
                    </div>
                </td>
            </tr> 
        </tbody>
    </table> 
</div>