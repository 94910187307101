import { AlertsNotificationsComponent } from './alerts-notifications.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    AlertsNotificationsComponent,
  ],
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [
    AlertsNotificationsComponent
  ]
})
export class AlertsNotificationsModule { }
