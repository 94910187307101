<div class="side-panel">
    <div class="panel-header">
        <span class="header">Notifications ({{count}})</span>
        <button class="btn btn-default clear" (click)="deleteAll()" *ngIf="count > 0">
            <fa-icon icon="trash"></fa-icon>
            <span>Clear All</span>
        </button>
        <button class="btn btn-default close" (click)="closeSidePanel()">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <div class="panel-body">
        <app-alerts-notifications *ngIf="showNotifications"></app-alerts-notifications>
    </div>
</div>