import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cellDisplay'
})
export class CellDisplayPipe implements PipeTransform {

  transform(value: any, options: Array<any> | null): any {
    if(options){
      var index = options.findIndex((option: any) => option.code == value);
      if(index >= 0)
        return options[index].name;
    }
    return value;
  }

}
