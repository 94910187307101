import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module'
import { HeaderFiltersComponent } from '../header-filters/header-filters.component';
import { HeaderComponent } from './header.component';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderFiltersComponent
  ],
  imports: [
    DropdownModule,
    ButtonModule,
    SharedModule,
    RouterModule,
    ScrollingModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
