import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpRequestService } from 'src/app/services/http-request/http-request.service';
import { LoginStatusService } from 'src/app/services/login-status/login-status.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { ToastServiceService } from 'src/app/services/toastService/toast-service.service';
import { trackingFilters , advanceFilters,deviceFilters } from "../../models/filters"

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss']
})
export class FilterPanelComponent implements OnInit {
  @Output() filterContent: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  
  filters: any = {};
  aFilters: any = advanceFilters;
  showFilters: boolean = false;

  showAdvFilters:boolean=false;
  showButton:boolean=true;
  aFilter: string = 'all';
  paFilter: string = 'all';

  constructor(
    private actModal: NgbActiveModal,
    private httpService: HttpRequestService,
    private sharedService: LoginStatusService,
    public toastService: ToastServiceService,
    public subscribe: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.showFilters = false;
    switch(this.data){
      case  'deviceFilter':
        var data = JSON.stringify(deviceFilters);
        this.filters = JSON.parse(data);
        this.showButton=false;
        break;
      case 'trackingFilter':
        var data = JSON.stringify(trackingFilters);
        this.filters = JSON.parse(data);
        this.showButton=true;
        break;
    }
    this.aFilter = this.subscribe.current_afilter;
    this.paFilter = this.subscribe.current_afilter;    
    var keys = Object.keys(this.subscribe.filter_payload).length; 
    
    if(keys == 0){
      this.filters.map((filter: any) => {
      
        if(filter.input_type == 'combo'){
          this.subscribe.filter_payload[filter.field_name] = {
            argument: filter.default,
            operator: filter.operators[0].code
          };
        }
        else{
          this.subscribe.filter_payload[filter.field_name] = {
            argument: filter.default,
            operator: '='
          };
        }
          
        if(filter.input_type == 'select'){
          if(filter.input_link)
            this.getOptions(filter);
        };
        
      });
    }
    else{
      this.filters.map((filter: any) => {
      
        if(filter.input_type == 'select'){
          if(filter.input_link)
            this.getOptions(filter);
        };
        
      });
    }
    this.subscribe.cfilters = this.filters;
    this.showFilters = true;
  };

  getOptions(filter): void{
    this.httpService.request(filter.input_link, {}).subscribe(
      (response: any) => {   

        if(response['status'] === 'success'){
          filter.options = response.data;
        } else {
          if (response['success'] === false) {
            this.closeSidePanel();
            this.sharedService.sessionExpired('session')
          }
          else {
            this.toastService.show('Data', 'No data present', 'bg-danger', {});
          }
        }
      }
    )
  };

  filterData(): void{
    var data = JSON.stringify(this.subscribe.filter_payload);
    data = JSON.parse(data);
    if(this.paFilter !== this.aFilter){
      delete data[this.paFilter];
      data[this.aFilter] = {
        argument : true
      };
    } else{
      data[this.aFilter] = {
        argument : true
      };
    }

    for (const [key, value] of Object.entries(data)) {
      if(value['argument'] == "" || value['argument'] == null || value['argument'] == 0 || value['operator'] == "")
        delete data[key]
    }

    this.paFilter = this.aFilter;
    this.subscribe.current_afilter = this.aFilter;
    
    this.sendFilter(data);
    this.closeSidePanel()
  }
  openShowAdv():void{
      this.showAdvFilters = true;
  };

  sendFilter(payload: any): void{
    this.filterContent.emit({status:true, data:payload})
  };
  clearFilter(payload: any={}):void{
    this.filterContent.emit({status:true, payload})
    this.closeSidePanel(true);

  }
  closeSidePanel(type: boolean = false){
    this.actModal.dismiss();
    if(type){
      this.showFilters = false;
      this.subscribe.filter_payload={};
    }
  }
}
