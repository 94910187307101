import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { config } from 'src/environments/config';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  //URL CONFIGURATION
  public url_config: any = {
    "css": {
      "primaryColor": "",
      "primaryBgColor": "",
      "secondaryColor": "",
      "secondaryBgColor": ""
    },
    "img": {
      "favicon": "",
      "loginLogo": "",
      "headerLogo": "",
      "headerLogoDark": ""
    },
    "info": {
      "copyText": "",
      "siteTitle": ""
    }
  };
  
  public default_config: any = {
    "css": {
      "primaryColor": "#000",
      "primaryBgColor": "rgb(255,255,255)",
      "secondaryColor": "#000",
      "secondaryBgColor": "rgb(255,255,255)"
    },
    "img": {
      "favicon": "https://res.cloudinary.com/emurontech/image/upload/v1609405655/logos/favicon_emuron.png",
      "loginLogo": "https://res.cloudinary.com/emurontech/image/upload/v1609401829/logos/logo_emuron.png",
      "headerLogo": "https://res.cloudinary.com/emurontech/image/upload/v1609401829/logos/logo_emuron.png",
      "headerLogoDark": "https://res.cloudinary.com/emurontech/image/upload/v1609405549/logos/logo_emuron_dark.png"
    },
    "info": {
      "copyText": "copyright",
      "siteTitle": "Emuron"
    }
  };

  //HEADER FILTER STATUS
  public hf_status: boolean = false;
  public display_mode: string = "DM";
  
  //FILTER PAYLOAD
  public cfilters: any = [];
  public filter_payload: any = {};

  private _filter: BehaviorSubject<any> = new BehaviorSubject(false)
  public filter = this._filter.asObservable()

  public current_filter: any = {};
  public current_afilter: string = 'all';

  updateFilterData(info){
    this.current_filter = info.data;
    this._filter.next(info.status);
  }
  
  //HEADER SEARCH LIST
  public showList: boolean = false;

  //REMOTE ACCESS
  public current_status: string = '';
  public timeInterval: Subscription;

  //PORTAL NAME
  stored_data: any = localStorage.getItem(config.license_code);
  license_code: any = null

  public portal_type: string = '';
  deviceInfo = null;

  constructor() { 
    if(this.stored_data){
      this.license_code = JSON.parse(this.stored_data);
    }
    this.portal_type = (this.license_code) ? this.license_code.user_details.portal_type : ''; 
    
  }
  public innerWidth: any;

  setDisplayMode(){
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth)
    if(this.innerWidth <= 768){

      this.display_mode = "PM";
    }
    else if (this.innerWidth > 768 && this.innerWidth <= 1024){
      this.display_mode = "LM";
    } else {
      this.display_mode = 'DM';
    }
    // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/.test(navigator.userAgent)){
     
    // }else{
      
    // }
  };

}
