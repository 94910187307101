import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.scss']
})
export class TableModalComponent implements OnInit {
  @Input() title: any;
  @Input() table_data: any;
  
  constructor(
    private modalService: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  closeModal(): void{
    const modalRef = this.modalService.dismiss();
  }
}
